import React, { useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import CurrencyInput from 'react-currency-input';
import moment from 'moment';
import { criaEvento, buscaEvento, atualizaEvento, criaVideo, buscaVideos, removeVideo, atualizaVideo } from '../../../services/mysql'

export default function CadastroProdutos(props) {
    const [dados] = useState(props.location.state ? props.location.state.produto : '')
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('i'))

    const [imagem, setImagem] = useState('')
    const [upload, setUpload] = useState('')

    // Dados Academia
    const [nomeAcademia, setNomeAcademia] = useState('')
    const [dono, setDono] = useState('')
    const [descricao, setDescricao] = useState('')
    const [status, setStatus] = useState('')

    // Dados do vídeo
    const [idVideo, setIdVideo] = useState('')
    const [listaVideos, setListaVideos] = useState([])
    const [nomeVideo, setNomeVideo] = useState('')
    const [imagemVideo, setImagemVideo] = useState('')
    const [urlVideo, setUrlVideo] = useState('')
    const [urlDownload, setUrlDownload] = useState('')
    const [descricaoVideo, setDescricaoVideo] = useState('')
    const [statusVideo, setStatusVideo] = useState('')
    const [valor, setValor] = useState(0)

    useEffect(() => {
        if (id) {
            mensagemLoading('Carregando dados..')
            buscaEvento(id).then((res) => {
                if (res.erro) {
                    mensagemErro('Erro ao buscar academia')
                } else {
                    setImagem(res.evento.imagem)
                    setNomeAcademia(res.evento.nome)
                    setDono(res.evento.dono)
                    setDescricao(res.evento.descricao)
                    setStatus(res.evento.status)
                    setListaVideos(res.videos)
                    Swal.close()
                }
            })
        }

    }, [])

    function cadastraEvento() {
        if (!nomeAcademia) {
            mensagemAlerta('Digite o nome da Academia')
            return
        }
        if (!status) {
            mensagemAlerta('Escolha o status da academia')
            return
        }
        const data = {
            imagem: imagem,
            nome: nomeAcademia,
            dono: dono,
            descricao: descricao,
            status: status,
            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
            urlImagem: ''
        }
        if (id) {
            mensagemLoading('Atualizando evento..')
            atualizaEvento(id, data).then((res) => {
                if (res.erro) {
                    mensagemErro(res.message)
                } else {
                    mensagemSucesso(res.message)
                }
            })
        } else {
            mensagemLoading('Cadastrando evento..')
            criaEvento(data).then((res) => {
                if (res.erro) {
                    mensagemErro('Erro ao cadastrar evento')
                } else {
                    mensagemSucesso(res.message)
                }
            })
        }

    }

    function cadastraVideo() {
        if (!nomeVideo) {
            mensagemAlerta('Digite o nome do Vídeo')
            return
        }
        if (!urlVideo) {
            mensagemAlerta('Digite a URL do vídeo')
            return
        }
        if (valor === 0) {
            mensagemAlerta('Digite o valor do vídeo')
            return
        }
        const data = {
            id: idVideo ? idVideo : '',
            imagem: imagemVideo,
            idEvento: id,
            nome: nomeVideo,
            url: urlVideo,
            urlDownload: urlDownload,
            descricao: descricaoVideo,
            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
            valor: parseFloat(valor),
            status: statusVideo
        }
        if (idVideo) {
            mensagemLoading('Atualizando Vídeo..')
            atualizaVideo(data).then((res) => {
                if (res.erro) {
                    mensagemErro('Erro ao atualizar vídeo')
                } else {
                    mensagemSucessoVideo(res.message)
                }
            })
        } else {
            mensagemLoading('Cadastrando Vídeo..')
            criaVideo(data).then((res) => {
                if (res.erro) {
                    console.log(res)
                    mensagemErro('Erro ao cadastrar vídeo')
                } else {
                    mensagemSucessoVideo(res.message)
                }
            })
        }

    }
    function deletaVideo(item) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover o vídeo ' + item.nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Deletando Vídeo..')
                removeVideo(item.id).then((res) => {
                    if (res.erro) {
                        mensagemErro('Erro ao remover vídeo')
                    } else {
                        mensagemSucessoVideo('Vídeo removido com sucesso')
                    }
                })
            }
        });
    }

    function detalheVideo(item) {
        setImagemVideo(item.imagem)
        setIdVideo(item.id)
        setNomeVideo(item.nome)
        setDescricaoVideo(item.descricao)
        setUrlVideo(item.url)
        setUrlDownload(item.urlDownload)
        setValor(item.valor)
        setStatusVideo(item.status)
    }


    // Handlers Academia
    const handleImagem = (event) => {
        event.persist();
        setImagem(event.target.value)
    }
    const handleNomeAcademia = (event) => {
        event.persist();
        setNomeAcademia(event.target.value);
    }
    function handleValor(event, maskedvalue, floatvalue) {
        setValor(maskedvalue);
    }
    const handleStatusAcademia = (event) => {
        event.persist();
        setStatus(event.target.value);
    }

    // Handlers Videos
    const handleImagemVideo = (event) => {
        event.persist();
        setImagemVideo(event.target.value)
    }
    const handleNomeVideo = (event) => {
        event.persist();
        setNomeVideo(event.target.value);
    }
    const handleUrlVideo = (event) => {
        event.persist();
        setUrlVideo(event.target.value);
    }
    const handleUrlDownload = (event) => {
        event.persist();
        setUrlDownload(event.target.value);
    }
    const handleDescricaoVideo = (event) => {
        event.persist();
        setDescricaoVideo(event.target.value);
    }
    function handleValor(event, maskedvalue, floatvalue) {
        setValor(maskedvalue);
    }
    const handleStatusVideo = (event) => {
        event.persist();
        setStatusVideo(event.target.value);
    }


    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/eventos'
        })
    }
    function mensagemLink(msg) {
        Swal.fire('Copie abaixo seu link', msg, 'success')
    }
    function mensagemSucessoVideo(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            setListaVideos([])
            setNomeVideo('')
            setImagemVideo('')
            setUrlVideo('')
            setDescricaoVideo('')
            setUrlDownload('')
            setValor(0)
            setIdVideo('')
            buscaVideos(id).then((res) => {
                if (res.erro) {
                    mensagemErro('Erro ao buscar vídeos')
                } else {
                    setListaVideos(res)
                }
            })
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-4">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <h5 className="card-header">{id && nomeAcademia ? nomeAcademia : 'Novo Evento'}</h5>
                                                </div>
                                            </div>

                                            {/* Account */}
                                            <div className="card-body">
                                                <div onsubmit="return false">
                                                    <div className="row">
                                                        <div className="card-body row">
                                                            <div className="d-flex align-items-start align-items-sm-center gap-4 col-md-4">
                                                                <img src={upload ? upload : imagem ? imagem : 'https://static.vecteezy.com/system/resources/previews/028/047/017/non_2x/3d-check-product-free-png.png'} alt="user-avatar" className="d-block rounded" height={200} width={200} id="uploadedAvatar" />
                                                            </div>
                                                        </div>
                                                        <div class="mb-3 col-md-12">
                                                            <label class="form-label" for="basic-icon-default-fullname">URL da imagem</label>
                                                            <div class="input-group input-group-merge">
                                                                <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                    class="bx bx-image"></i></span>
                                                                <input value={imagem} onChange={handleImagem} type="text" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div class="mb-3 col-md-6">
                                                            <label class="form-label" for="basic-icon-default-fullname">Nome do Evento</label>
                                                            <div class="input-group input-group-merge">
                                                                <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                    class="bx bx-home"></i></span>
                                                                <input value={nomeAcademia} onChange={handleNomeAcademia} type="text" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div class="mb-3 col-md-2">
                                                            <label class="form-label" for="basic-icon-default-fullname">Status</label>
                                                            <select id="defaultSelect" className="form-select" onChange={handleStatusAcademia} >
                                                                <option>Selecione</option>
                                                                <option value={'ATIVO'} selected={id && status === 'ATIVO' ? 'selected' : null} >Ativo</option>
                                                                <option value={'INATIVO'} selected={id && status === 'INATIVO' ? 'selected' : null} >Inativo </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        {id ?
                                                            <>
                                                                <button onClick={() => cadastraEvento()} type="submit" className="btn btn-primary me-2">Atualizar Evento</button>
                                                            </>
                                                            :
                                                            <button onClick={() => cadastraEvento()} type="submit" className="btn btn-primary me-2">Cadastra Evento</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /Account */}
                                        </div>
                                    </div>
                                    {id ?
                                        <div className="col-md-12">
                                            <div className="card mb-4">
                                                <div className='row'>
                                                    <div className='col-md-10'>
                                                        <h5 className="card-header">{'Vídeos do Evento'}</h5>
                                                    </div>
                                                </div>

                                                {/* Account */}
                                                <div className="card-body">
                                                    <div onsubmit="return false">
                                                        <div className="row">
                                                            <div class="mb-3 col-md-12">
                                                                <label class="form-label" for="basic-icon-default-fullname">URL da imagem do Video</label>
                                                                <div class="input-group input-group-merge">
                                                                    <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                        class="bx bx-image"></i></span>
                                                                    <input value={imagemVideo} onChange={handleImagemVideo} type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-6">
                                                                <label class="form-label" for="basic-icon-default-fullname">Nome do Vídeo</label>
                                                                <div class="input-group input-group-merge">
                                                                    <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                        class="bx bx-box"></i></span>
                                                                    <input value={nomeVideo} onChange={handleNomeVideo} type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-6">
                                                                <label class="form-label" for="basic-icon-default-fullname">Url do Vídeo</label>
                                                                <div class="input-group input-group-merge">
                                                                    <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                        class="bx bx-box"></i></span>
                                                                    <input value={urlVideo} onChange={handleUrlVideo} type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-6">
                                                                <label class="form-label" for="basic-icon-default-fullname">Url de Download Video</label>
                                                                <div class="input-group input-group-merge">
                                                                    <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                        class="bx bx-box"></i></span>
                                                                    <input value={urlDownload} onChange={handleUrlDownload} type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-2">
                                                                <label class="form-label" for="basic-icon-default-fullname">Status</label>
                                                                <select id="defaultSelect" className="form-select" onChange={handleStatusVideo} >
                                                                    <option>Selecione</option>
                                                                    <option value={'ATIVO'} selected={id && statusVideo === 'ATIVO' ? 'selected' : null} >Ativo</option>
                                                                    <option value={'INATIVO'} selected={id && statusVideo === 'INATIVO' ? 'selected' : null} >Inativo </option>
                                                                </select>
                                                            </div>
                                                            <div class="mb-3 col-md-12">
                                                                <label class="form-label" for="basic-icon-default-fullname">Descrição do Vídeo</label>
                                                                <div class="input-group input-group-merge">
                                                                    <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                        class="bx bx-file"></i></span>
                                                                    <textarea value={descricaoVideo} onChange={handleDescricaoVideo} type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-3" >
                                                                <label class="form-label" for="basic-icon-default-fullname">Valor do Vídeo</label>
                                                                <div class="input-group input-group-merge">
                                                                    <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                        class="bx bx-money"></i></span>
                                                                    {/* <input onChange={handleValor} value={valor} type="text" class="form-control" /> */}
                                                                    <CurrencyInput value={valor} onChange={handleValor} className="form-control" id="email2" prefix="R$ " decimalSeparator="," thousandSeparator="." />
                                                                </div>
                                                            </div>
                                                            <div className='mb-3 col-md-2' style={{ marginTop: 30 }}>
                                                                {idVideo ?
                                                                    <button onClick={() => cadastraVideo()} type="submit" className="btn btn-primary me-2">Atualizar</button>
                                                                    :
                                                                    <button onClick={() => cadastraVideo()} type="submit" className="btn btn-primary me-2">Adicionar</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row">
                                                            <div className='mb-3 col-md-12' style={{ marginTop: 20 }}>
                                                                <h5 >Vídeos da Academia </h5>
                                                            </div>
                                                            <div className="table-responsive text-nowrap" >
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Posição</th>
                                                                            <th>Imagem</th>
                                                                            <th>Nome</th>
                                                                            <th>Descrição</th>
                                                                            <th>Valor</th>
                                                                            <th>Sala Vip</th>
                                                                            <th>Status</th>
                                                                            <th>Editar</th>
                                                                            <th>Remover</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="table-border-bottom-0">
                                                                        {listaVideos.map((video, index) =>
                                                                            <tr key={video.id}>
                                                                                <td>{(index + 1)}</td>
                                                                                <td>{<img src={video.imagem} alt="user-avatar" className="d-block rounded" height={50} width={50} id="uploadedAvatar" />}</td>
                                                                                <td>{video.nome}</td>
                                                                                <td>{video.descricao}</td>
                                                                                <td>{video.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                                <td>
                                                                                    <button onClick={() => window.location = '/listaVipEventos?i=' + video.id + id + '&video=' + video.nome} type="button" className="btn rounded-pill btn-icon btn-info">
                                                                                        <span className="tf-icons bx bx-search" />
                                                                                    </button>
                                                                                </td>
                                                                                <td>
                                                                                    {video.status === 'ATIVO' ?
                                                                                        <span class="badge bg-label-success me-1">Ativo</span>
                                                                                        :
                                                                                        <span class="badge bg-label-danger me-1">Inativo</span>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <button onClick={() => detalheVideo(video)} type="button" className="btn rounded-pill btn-icon btn-warning">
                                                                                        <span className="tf-icons bx bx-edit" />
                                                                                    </button>
                                                                                </td>
                                                                                <td>
                                                                                    <button onClick={() => deletaVideo(video)} type="button" className="btn rounded-pill btn-icon btn-danger">
                                                                                        <span className="tf-icons bx bx-trash" />
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                        {listaVideos.length === 0 ?
                                                                            <p style={{ textAlign: 'center', padding: 10 }} >Sem vídeos adicionados</p>
                                                                            :
                                                                            null
                                                                        }

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* /Account */}
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                            </div>


                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}