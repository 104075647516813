import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { atualizaInformacoes, buscaInformacoes } from '../../services/mysql'

export default function Termo(props) {
    // Dados Academia
    const [informacoes, setInformacoes] = useState([])

    // Sala Vip
    const [termo, setTermo] = useState('')

    useEffect(() => {
        mensagemLoading('Carregando dados..')
        buscaInformacoes().then((res) => {
            if (res.erro) {
                mensagemErro('Erro ao buscar informações')
            } else {
                setInformacoes(res[0])
                setTermo(res[0].termo)
                Swal.close()
            }
        })
    }, [])

    function cadastraEvento() {
        if (!termo) {
            mensagemAlerta('Por favor, digite o termo')
            return
        }

        const data = {
            termo: termo,
            telefone: '',
            email: ''
        }
        mensagemLoading('Atualizando informacoes..')
        atualizaInformacoes(data).then((res) => {
            if (res.erro) {
                console.log(res)
                mensagemErro('Erro ao cadastrar informacoes')
            } else {
                mensagemSucesso(res.message)
            }
        })

    }



    // Handlers
    const handleTermo = (event) => {
        setTermo(event)
    }

    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/admin'
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-4">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <h5 className="card-header">Informações do Sistema</h5>
                                                </div>
                                            </div>

                                            {/* Account */}
                                            <div className="card-body">
                                                <div onsubmit="return false">
                                                    <div className="row">
                                                        <div class="mb-3 col-md-12">
                                                            <label class="form-label" for="basic-icon-default-fullname">Termo</label>
                                                            <SunEditor
                                                                lang="pt_br"
                                                                placeholder="informações"
                                                                onChange={handleTermo}
                                                                setContents={termo}
                                                                setOptions={{
                                                                    buttonList: [
                                                                        ['undo', 'redo'],
                                                                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                                                        ['table', 'link', 'image'],
                                                                        ['list', 'align', 'outdent', 'indent'],
                                                                        ['table', 'link', 'image', 'video'],
                                                                        ['font', 'fontSize', 'formatBlock', 'paragraphStyle']
                                                                    ]
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <button onClick={() => cadastraEvento()} type="submit" className="btn btn-primary me-2">Atualizar Informações</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /Account */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}