import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'

// Site
import Login from './pages/Login'
import Cadastro from './pages/Cadastro';
import EsqueciSenha from './pages/EsqueciSenha';

// Informações
import Informacoes from './pages/Informacoes'

// Admin
import Admin from './pages/Admin'

// Eventos
import Eventos from './pages/Eventos';
import CadastroEventos from './pages/Eventos/Cadastro';
import ListaVipEventos from './pages/Eventos/Vip';

// Transmissoes
import Transmissoes from './pages/Transmissoes';
import CadastroTransmissao from './pages/Transmissoes/Cadastro';

// Atleta
import HomeAtleta from './pages/Atleta/Home';
import VideosAtleta from './pages/Atleta/Videos';
import TransmissaoAtleta from './pages/Atleta/Transmissao';
import TrocaSenha from './pages/Atleta/TrocaSenha';

// Usuários
import Usuarios from './pages/Usuarios';

// Pagamentos
import Pagamentos from './pages/Pagamentos';
import Assinatura from './pages/Assinatura';
import Sucesso from './pages/Sucesso';

// Relatorios
import Auditoria from './pages/Auditoria'

export default function Rotas() {
    return (
        <div className="App">
            <BrowserRouter>

                {/* Auth */}
                <Route path="/assinatura" exact={true} component={Assinatura} />
                <Route path="/sucesso" exact={true} component={Sucesso} />
                <Route path="/" exact={true} component={Login} />
                <Route path="/cadastro" exact={true} component={Cadastro} />
                <Route path="/esqueciSenha" exact={true} component={EsqueciSenha} />

                {/* Informacoes */}
                <Route path="/informacoes" exact={true} component={Informacoes} />

                {/* Admin */}
                <Route path="/admin" exact={true} component={Admin} />

                {/* Eventos */}
                <Route path="/eventos" exact={true} component={Eventos} />
                <Route path="/cadastroEvento" exact={true} component={CadastroEventos} />
                <Route path="/listaVipEventos" exact={true} component={ListaVipEventos} />

                {/* Transmissoes */}
                <Route path="/transmissoes" exact={true} component={Transmissoes} />
                <Route path="/cadastroTransmissao" exact={true} component={CadastroTransmissao} />

                {/* Atletas */}
                <Route path="/homeAtleta" exact={true} component={HomeAtleta} />
                <Route path="/videosAtleta" exact={true} component={VideosAtleta} />
                <Route path="/transmissaoAtleta" exact={true} component={TransmissaoAtleta} />
                <Route path="/trocaSenha" exact={true} component={TrocaSenha} />

                {/* Usuários */}
                <Route path="/usuarios" exact={true} component={Usuarios} />

                {/* Pagamentos */}
                <Route path="/pagamentos" exact={true} component={Pagamentos} />

                {/* Relatorios */}
                <Route path="/auditoria" exact={true} component={Auditoria} />

            </BrowserRouter>
        </div>
    );
}