import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { auditoria, novaSenha } from '../../../services/mysql'
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

export default function TrocaSenha() {
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('i'))
    const [senha, setSenha] = useState('')
    const [confirmaSenha, setConfirmaSenha] = useState('')

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userd7'))
        const data = {
            idUsuario: id,
            acao: 'Acessou a página de troca de senha'
        }
        auditoria(data);
    }, [])

    function atualizarSenha() {
        if (!senha) {
            mensagemAlerta('Digite a nova senha')
            return
        }
        if (!confirmaSenha) {
            mensagemAlerta('Confirme a nova senha')
            return
        }
        if (senha !== confirmaSenha) {
            mensagemAlerta('As senhas não são iguais')
            return
        }
        mensagemLoading('Atualizando Senha..')
        const data = {
            id: id,
            senha: senha,
        }
        novaSenha(data).then((res) => {
            if (res.erro) {
                Swal.close()
                mensagemErro(res.message)
            } else {
                mensagemSucesso(res.message)
                const data = {
                    idUsuario: id,
                    acao: 'Realizou a troca de senha com sucesso '
                }
                auditoria(data);
            }
        })

    }

    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleConfirmaSenha = (event) => {
        event.persist();
        setConfirmaSenha(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.history.back()
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-4">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <h5 className="card-header">Troca Senha</h5>
                                                </div>
                                            </div>

                                            {/* Account */}
                                            <div className="card-body">
                                                <div onsubmit="return false">
                                                    <div className="row">
                                                        <div class="mb-3 col-md-6">
                                                            <label class="form-label" for="basic-icon-default-fullname">Nova Senha</label>
                                                            <div class="input-group input-group-merge">
                                                                <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                    class="bx bx-key"></i></span>
                                                                <input value={senha} onChange={handleSenha} type="text" class="form-control" />
                                                            </div>
                                                        </div>
                                                        <div class="mb-3 col-md-6">
                                                            <label class="form-label" for="basic-icon-default-fullname">Confirme a Nova Senha</label>
                                                            <div class="input-group input-group-merge">
                                                                <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                    class="bx bx-key"></i></span>
                                                                <input value={confirmaSenha} onChange={handleConfirmaSenha} type="text" class="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <button onClick={() => atualizarSenha()} type="submit" className="btn btn-primary me-2">Atualizar</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /Account */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}