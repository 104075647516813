import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import { listaUsuarios, deletaUsuario, deletaUsuariosMassa, buscaUsuario } from '../../services/mysql'

export default function Usuarios() {
    const [usuarios, setUsuarios] = useState([])
    const [selecionados, setSelecionados] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState("");


    useEffect(() => {
        carregarUsuarios(currentPage);
    }, [])

    const carregarUsuarios = (page) => {
        mensagemLoading('Buscando Usuarios...')
        listaUsuarios().then((res) => {
            setUsuarios([])
            Swal.close();
            setUsuarios(res);
            // setTotalPages(res.totalPages);
        });
    };
    const mudarPagina = (novaPagina) => {
        if (novaPagina >= 1 && novaPagina <= totalPages) {
            setCurrentPage(novaPagina);
            carregarUsuarios(novaPagina);
        }
    };
    const searchUsuario = () => {
        if(!search){
            mensagemAlerta('Digite o nome do usuário')
            return
        }
        const data ={
            nome:search,
            page:1
        }
        mensagemLoading('BUscando ...')
        buscaUsuario(data).then((res)=>{
            setUsuarios([])
            Swal.close();
            console.log(res)
            if(res.usuarios.length === 0){
                mensagemAlerta('Nenhum usuário encontrado')
            }else{
                setUsuarios(res.usuarios);
                setTotalPages(res.totalPages);
            }
        })
    };

    const toggleSelecionado = (id) => {
        setSelecionados((prev) =>
            prev.includes(id) ? prev.filter((uid) => uid !== id) : [...prev, id]
        );
    };

    const excluirSelecionados = () => {
        Swal.fire({
            title: 'Aviso',
            text: 'Você deseja excluir todos esses usuarios?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Excluir',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                console.log(selecionados)
                mensagemLoading('Realizando exclusão dos usuarios..')
                deletaUsuariosMassa({ ids: selecionados }).then((res) => {
                    if (res.erro) {
                        mensagemErro(res.message)
                    } else {
                        mensagemSucesso(res.message)
                    }
                })
            }
        });
    };

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    function removeUsuario(item) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover o usuario ' + item.nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Deletando Usuario..')
                deletaUsuario(item.id).then((res) => {
                    console.log(res)
                    if (res.erro) {
                        mensagemErro(res.message)
                    } else {
                        mensagemSucesso('Usuário removido com sucesso')
                    }
                })

            }
        });
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            mensagemLoading('Atualizando Usuários...')
            carregarUsuarios(currentPage);
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 " style={{ marginTop: 20 }}>
                                <div className="card">
                                    <div className='row card-body'>
                                        <div className='col-md-10'>
                                            <h5 className="card-header">Usuários</h5>
                                        </div>
                                        {selecionados.length > 1 ?
                                            <div className='col-md-2' style={{ padding: 10 }}>
                                                <button onClick={() => excluirSelecionados()} className="btn btn-danger d-grid " type="submit">Remover </button>
                                            </div>
                                            :
                                            null
                                        }
                                        <div className='col-md-6 '>
                                            <input
                                                type="text"
                                                placeholder="Buscar usuário..."
                                                className="form-control mb-3"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-2'>
                                            <button onClick={() => searchUsuario()} className="btn btn-primary d-grid " type="submit">Buscar </button>
                                        </div>
                                        <div className='col-md-2'>
                                            <button onClick={() => carregarUsuarios(currentPage)} className="btn btn-info d-grid " type="submit">Todos </button>
                                        </div>

                                    </div>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) =>
                                                                setSelecionados(
                                                                    e.target.checked
                                                                        ? usuarios
                                                                            .filter((usuario) => usuario.perfil !== "ADMIN")
                                                                            .map((usuario) => usuario.id)
                                                                        : []
                                                                )
                                                            }
                                                            checked={
                                                                selecionados.length > 0 &&
                                                                selecionados.length ===
                                                                usuarios.filter((usuario) => usuario.perfil !== "ADMIN").length
                                                            }
                                                        />
                                                    </th>
                                                    <th>Nome</th>
                                                    <th>CPF</th>
                                                    <th>Email</th>
                                                    <th>Perfil</th>
                                                    <th>Deletar</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {usuarios.map((usuario) =>
                                                    <tr key={usuario.id}>
                                                        <td>
                                                            {usuario.perfil !== "ADMIN" && (
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selecionados.includes(usuario.id)}
                                                                    onChange={() => toggleSelecionado(usuario.id)}
                                                                />
                                                            )}
                                                        </td>
                                                        <td>{usuario.nome}</td>
                                                        <td>{usuario.cpf}</td>
                                                        <td>{usuario.email}</td>
                                                        <td>{usuario.perfil}</td>
                                                        <td>
                                                            <button onClick={() => usuario.perfil !== 'ADMIN' ? removeUsuario(usuario) : null} type="button" className="btn rounded-pill btn-icon btn-danger">
                                                                <span className="tf-icons bx bx-trash" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                                {usuarios.length === 0 ?
                                                    <p style={{ textAlign: 'center', padding: 10 }} >Sem usuários cadastrados</p>
                                                    :
                                                    null
                                                }

                                            </tbody>
                                        </table>
                                        {/* <div className="pagination" style={{ display: "flex", justifyContent: "center", gap: "5px", marginTop: "20px" }}>
                                           
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => mudarPagina(currentPage - 1)}
                                                disabled={currentPage === 1}
                                            >
                                                Anterior
                                            </button>

                                            
                                            {[...Array(totalPages)].map((_, index) => {
                                                const pageNumber = index + 1;

                                                
                                                if (
                                                    pageNumber === 1 ||
                                                    pageNumber === totalPages ||
                                                    (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                                                ) {
                                                    return (
                                                        <button
                                                            key={pageNumber}
                                                            className={`btn ${currentPage === pageNumber ? "btn-dark" : "btn-outline-primary"}`}
                                                            onClick={() => mudarPagina(pageNumber)}
                                                        >
                                                            {pageNumber}
                                                        </button>
                                                    );
                                                }

                                                
                                                if (
                                                    (pageNumber === currentPage - 2 && currentPage > 3) ||
                                                    (pageNumber === currentPage + 2 && currentPage < totalPages - 2)
                                                ) {
                                                    return <span key={pageNumber} style={{ padding: "5px 10px" }}>...</span>;
                                                }

                                                return null;
                                            })}

                                            
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => mudarPagina(currentPage + 1)}
                                                disabled={currentPage === totalPages}
                                            >
                                                Próximo
                                            </button>
                                        </div> */}
                                    </div>
                                </div>

                            </div>

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}