import React, { useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import { listaEventos, listaTransmissoes, auditoria } from '../../../services/mysql'
import moment from 'moment/moment';

export default function Atleta() {
    const [academias, setAcademias] = useState([])
    const [transmissoes, setTransmissoes] = useState([])
    const [tipo, setTipo] = useState('')
    const [search, setSearch] = useState('')
    const [aceito, setAceito] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        mensagemLoading('Buscando Dados...')
        listaEventos().then((res) => {
            Swal.close()
            setAcademias(res)
        })
        listaTransmissoes().then((res) => {
            Swal.close()
            setTransmissoes(res)
        })
        const termosAceitos = localStorage.getItem("termosAceitos");
        if (termosAceitos) {
            setAceito(true);
        }
        const user = JSON.parse(localStorage.getItem('userd7'))
        const data = {
            idUsuario: user.id,
            acao: 'Acesso a Página Inicial '
        }
        auditoria(data)
    }, [])

    const aceitarTermos = () => {
        localStorage.setItem("termosAceitos", "true");
        setAceito(true);
    };

    function detalheVideo(data) {
        window.location = `/videosAtleta?i=${data.id}&&a=${data.nome}`
    }
    function detalheTransmissao(data) {
        window.location = `/transmissaoAtleta?i=${data.id}&&a=${data.nome}`
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            {modalOpen ?
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setModalOpen(false)} >&times;</span>
                        <h2 style={{ textAlign: 'center' }}>Termos de Uso - TvCombate</h2>
                        <p>
                            Ao utilizar a plataforma TvCombate, você concorda com a coleta e processamento de seus dados pessoais, incluindo nome, e-mail, CPF e senha para cadastramento. Também realizamos vendas via Pix para acesso a eventos e transmissões ao vivo. Seus dados serão utilizados exclusivamente para fins de autenticação e processamento de pagamentos.
                        </p>
                        <p>
                            Ao aceitar estes termos, você concorda com nossa política de privacidade e uso da plataforma.
                        </p>
                        <button onClick={() => setModalOpen(false)} className="btn btn-info d-grid w-100" type="button">
                            Entendi
                        </button>
                        <p style={{ textAlign: 'center', fontSize: 10, marginTop: 20 }}>
                            Caso ficou alguma dúvida, entre em contato com a adminsitração
                        </p>
                    </div>
                </div>
                :
                null
            }

            <div className="layout-container">
                {/* <Menu /> */}
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            {tipo === 'eventos' ?
                                <div className="container-xxl flex-grow-1 container-p-y">
                                    <h3><b>Vídeos</b></h3>
                                    <a href='javascript:void(0)' onClick={() => setTipo('')} style={{ marginTop: 20, width: '50%' }} className="btn btn-primary d-grid w-10" type="submit">Tela Inicial</a>
                                    {academias && academias.length > 0 ?
                                        <div style={{ marginTop: 20 }} className="row">
                                            {academias.filter((d) => d.status === 'ATIVO').map((academia) =>
                                                <div className="col-sm-6 col-lg-6 mb-4">
                                                    <div className="card card-border-shadow-primary h-100">
                                                        <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                            <img style={{ width: 100, height: 100 }} src={academia.imagem} />
                                                            <h5 style={{ marginTop: 20 }}>{academia.nome}</h5>
                                                            <p className="mb-1">{academia.descricao}</p>
                                                            <button onClick={() => detalheVideo(academia)} style={{ marginTop: 20 }} className="btn btn-primary d-grid w-100" type="submit">Ver vídeos</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                : tipo === 'transmissao' ?
                                    <div className="container-xxl flex-grow-1 container-p-y">
                                        <h3><b>Transmissões Ao vivo</b></h3>
                                        <a href='javascript:void(0)' onClick={() => setTipo('')} style={{ marginTop: 20, width: '50%' }} className="btn btn-primary d-grid w-10" type="submit">Tela Inicial</a>
                                        {transmissoes && transmissoes.length > 0 ?
                                            <div style={{ marginTop: 20 }} className="row">
                                                {transmissoes.filter((d) => d.status === 'ATIVO').map((academia) =>
                                                    <div className="col-sm-6 col-lg-6 mb-4">
                                                        <div className="card card-border-shadow-primary h-100">
                                                            <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                                <img style={{ width: 100, height: 100 }} src={academia.urlImagem} />
                                                                <h5 style={{ marginTop: 20 }}>{academia.nome}</h5>
                                                                <p className="mb-1">{"A transmissão ocorrerá dia " + moment(academia.dataTransmissao).format('DD/MM/YYYY')}</p>
                                                                <button onClick={() => detalheTransmissao(academia)} style={{ marginTop: 20 }} className="btn btn-primary d-grid w-100" type="submit">Acessar Transmissão</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    <div className="container-xxl flex-grow-1 container-p-y">
                                        <h3><b>Olá, bem vindo</b></h3>
                                        <div className="row">
                                            <div className="col-sm-6 col-lg-6 mb-4">
                                                <div className="card card-border-shadow-primary h-100">
                                                    <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                        <img style={{ width: 100, height: 100 }} src='/images/icon_live.png' />
                                                        <h5 style={{ marginTop: 20 }}>Transmissões</h5>
                                                        <p className="mb-1">Assista as melhores transmissões ao vivo</p>
                                                        <button onClick={() => setTipo('transmissao')} style={{ marginTop: 20 }} className="btn btn-primary d-grid w-100" type="submit">Ver Transmissões</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-6 mb-4">
                                                <div className="card card-border-shadow-primary h-100">
                                                    <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                        <img style={{ width: 100, height: 100 }} src='/images/icon_video.png' />
                                                        <h5 style={{ marginTop: 20 }}>Vídeos</h5>
                                                        <p className="mb-1">Assista as melhores vídeos</p>
                                                        <button onClick={() => setTipo('eventos')} style={{ marginTop: 20 }} className="btn btn-primary d-grid w-100" type="submit">Ver Vídeos</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }


                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
            {!aceito ?
                <div style={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    background: "#000",
                    color: "#fff",
                    padding: "10px",
                    textAlign: "center"
                }}>
                    <span>
                        Ao continuar navegando, você concorda com nossos <a href="#" onClick={() => setModalOpen(true)} style={{ color: "#5882FA" }}>termos de uso</a>.
                    </span>
                    <button onClick={aceitarTermos} style={{
                        marginLeft: "10px",
                        padding: "5px 10px",
                        background: "#5882FA",
                        color: "#fff",
                        border: "none",
                        cursor: "pointer"
                    }}>
                        Li e aceito
                    </button>
                </div>
                :
                null
            }

        </div>

    );
}