import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import _ from 'lodash';
import { notificacao } from '../../services/mysql'

export default function Assinante() {
    const queryParams = new URLSearchParams(window.location.search);
    const [preapproval_id] = useState(queryParams.get('preapproval_id'))

    useEffect(() => {
        mensagemLoading('Redirecionando você')
        const data = {
            data: {
                id: preapproval_id
            }
        }
        notificacao(data).then((res)=>{
            window.location = 'https://revisemaisapp.com.br/'
        })
    }, [])

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            
        </div>

    );
}