import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import { listaEventos, buscaPagamentos, listaTransmissoes, listaAuditoria } from '../../services/mysql'
import moment from 'moment/moment';

export default function Auditoria() {
    const [auditoria, setAuditoria] = useState([])
    const [mesAno, setMesAno] = useState(moment().format('YYYY-MM'))
    const [email, setEmail] = useState('')
    const [tipoEvento, setTipoEvento] = useState('eventos')
    const [filtro] = useState({
        mesAno: moment().format('YYYY-MM'),
        email: '',
    })

    function filtrar() {
        if (!email && !mesAno) {
            mensagemAlerta('Por favor selecione nome e email para pesquisa')
            return
        }
        setAuditoria([])
        filtro.mesAno = mesAno
        filtro.email = email
        mensagemLoading('Buscando Dados...')
        listaAuditoria(filtro).then((res) => {
            if (res.erro) {
                Swal.close()
                mensagemErro(res.message)
            } else {
                Swal.close()
                console.log(res)
                setAuditoria(res)
            }
        })

    }

    const handleMesAno = (event) => {
        event.persist();
        setMesAno(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 " style={{ marginTop: 20 }}>
                                <div className="card">
                                    <div className='row'>
                                        <div className='col-md-10'>
                                            <h5 className="card-header">Auditoria</h5>
                                        </div>
                                    </div>
                                    <div className='card-body row'>
                                        <div class="col-md-4">
                                            <label class="form-label" for="basic-icon-default-fullname">Email</label>
                                            <input type="text" value={email} onChange={handleEmail} className="form-control" id="email" name="email-username" placeholder="Email do usuário" autofocus />
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-label" for="basic-icon-default-fullname">Mes/Ano</label>
                                            <input value={mesAno} onChange={handleMesAno} type="month" class="form-control" />
                                        </div>

                                        <div class="col-md-4" style={{ marginTop: 30 }}>
                                            <button onClick={() => filtrar()} type="submit" className="btn btn-primary me-2">Filtrar</button>
                                        </div>
                                    </div>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>CPF</th>
                                                    <th>Email</th>
                                                    <th>Ação</th>
                                                    <th>Data/Hora</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {auditoria.sort((a, b) => moment(b.dataPagamento).diff(moment(a.dataPagamento))).map((aud, index) =>
                                                    <tr key={index}>
                                                        <td>{aud.nome}</td>
                                                        <td>{aud.cpf}</td>
                                                        <td>{aud.email}</td>
                                                        <td>{aud.acao}</td>
                                                        <td>{moment(aud.dataPagamento).format('DD/MM/YYYY - HH:mm')}</td>
                                                    </tr>
                                                )}
                                                {auditoria.length === 0 ?
                                                    <p style={{ textAlign: 'center', padding: 10 }} >Sem informações</p>
                                                    :
                                                    null
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}