import React, { useRef, useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import Modal from './Modal';
import parse from 'html-react-parser';
import { buscaTransmissao, verificaTransmissao, criaTransmissaoUser, criaPix, buscaPagamento, buscaInformacoes, auditoria } from '../../../services/mysql'
import ModalPix from './ModalPix';

export default function Videos() {
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('i'))
    const [academia] = useState(queryParams.get('a'))
    const [transmissao, setTransmissão] = useState({})

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [isModalOpenPix, setIsModalOpenPix] = useState(false);
    const openModalPix = () => setIsModalOpenPix(true);
    const closeModalPix = () => setIsModalOpenPix(false);

    const [termo, setTermo] = useState('')

    const [urlVideo, setUrlVideo] = useState('')

    // Pagamento
    const [qrcode, setQrcode] = useState('')
    const [copiaCola, setCopiaCola] = useState('')
    const intervalId = useRef(null);

    useEffect(() => {
        mensagemLoading('Buscando Dados...')
        buscaTransmissao(id).then((res) => {
            if (res.erro) {
                Swal.close()
                mensagemErro('Erro ao buscar vídeos')
            } else {
                Swal.close()
                setTransmissão(res.transmissao)
                const user = JSON.parse(localStorage.getItem('userd7'))
                const data = {
                    idUsuario: user.id,
                    acao: 'Acessou a página de Transmissão ' + res.transmissao.nome
                }
                auditoria(data);
            }
        })
        buscaInformacoes().then((res) => {
            if (res.erro) {
                Swal.close()
                mensagemErro('Erro ao buscar Informações')
            } else {
                Swal.close()
                setTermo(res[0].termo)
            }
        })
    }, [])

    const startApiCalls = (pagamento, transmissao) => {
        fetchApiData(pagamento, transmissao);
        intervalId.current = setInterval(() => {
            fetchApiData(pagamento, transmissao);
        }, 10000);
    };

    const fetchApiData = async (pagamento, transmissao) => {
        try {
            buscaPagamento(pagamento).then((res) => {
                if (res.status === 'approved') {
                    const user = JSON.parse(localStorage.getItem('userd7'))
                    const data = {
                        idTransmissao: id,
                        idUser: user.id
                    }
                    criaTransmissaoUser(data).then((resTransmissao) => {
                        if (resTransmissao.erro) {
                            mensagemErro('Erro ao gerar transmissaão')
                            const data = {
                                idUsuario: user.id,
                                acao: 'O sistema mostrou erro no pagamento ' + transmissao.nome
                            }
                            auditoria(data);
                        } else {
                            closeModalPix()
                            setUrlVideo(transmissao.canalTransmissao)
                            openModal()
                            Swal.close()
                            const data = {
                                idUsuario: user.id,
                                acao: 'O sistema aprovou o pagamento e abriu o vídeo para assistir a transmissão ' + transmissao.nome
                            }
                            auditoria(data);
                        }
                    })
                    clearInterval(intervalId.current);
                }
            });
        } catch (err) {
            mensagemErro('Erro ao realizar pagamento');
        }
    };

    function realizaTransmissao() {
        const user = JSON.parse(localStorage.getItem('userd7'))
        const data = {
            idUsuario: user.id,
            acao: 'Clicou em detalhar a transmissão ' + transmissao.nome
        }
        auditoria(data);
        mensagemLoading('Verificando..')
        verificaTransmissao(user.id, id).then((res) => {
            if (res.erro) {
                mensagemErro('Erro ao verificar transmissao')
                const data = {
                    idUsuario: user.id,
                    acao: 'Gerou erro ao acessar a transmissão ' + transmissao.nome
                }
                auditoria(data);
            } else {
                if (res.transmissao || res.salaVip) {
                    Swal.close()
                    setUrlVideo(transmissao.canalTransmissao)
                    openModal()
                    const data = {
                        idUsuario: user.id,
                        acao: 'Acessou a transmissão ' + transmissao.nome + ' pelo canal '+transmissao.canalTransmissao
                    }
                    auditoria(data);
                } else {
                    Swal.fire({
                        title: 'Aviso',
                        text: 'Você precisa realizar o pagamento de ' + transmissao.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) + ' para assistir a transmissão ' + transmissao.nome + '! Deseja realizar o pagamento?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Pagar',
                        cancelButtonText: 'Agora Não'
                    }).then((result) => {
                        if (result.value) {
                            const dados = {
                                nome: 'TVCombate - Transmissão',
                                cpf: user.cpf,
                                email: user.email,
                                descricao: 'Transmissao - ' + transmissao.nome,
                                valor: parseFloat(transmissao.valor),
                            }
                            mensagemLoading('Gerando pix..')
                            criaPix(dados).then((res) => {
                                setQrcode(res.data.pagamento.qrcode)
                                setCopiaCola(res.data.pagamento.chave)
                                Swal.close()
                                startApiCalls(res.data.id, transmissao)
                                openModalPix()
                                const data = {
                                    idUsuario: user.id,
                                    acao: 'Gerou o pix da transmissão ' + transmissao.nome
                                }
                                auditoria(data);
                            })
                        }
                    });
                }
            }
        })
    }

    const extractFileIdGoogle = (url) => {
        const regex = /\/d\/(.*?)\//;  // Expressão regular para pegar o ID entre "/d/" e "/"
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const handleDownload = (urlVideo) => {
        if (!urlVideo) {
            alert('A URL do vídeo é necessária');
            return;
        }

        // Cria dinamicamente um link para fazer a requisição ao backend com a URL do vídeo
        const link = document.createElement('a');
        link.href = `https://app-3prv6jubba-uc.a.run.app/downloadVideo?url=${encodeURIComponent(urlVideo)}`;  // Envia a URL como query parameter

        // Força a abertura da janela de download
        link.target = '_blank';  // Abre o link em uma nova aba (alguns navegadores tratam isso como uma nova ação de download)
        link.download = '';      // O atributo download vazio forçará o diálogo de download para o usuário escolher onde salvar

        // Simula o clique no link para iniciar o download
        document.body.appendChild(link);
        link.click();

        // Remove o link do DOM após o clique
        document.body.removeChild(link);
    };

    function assitirVideo(item) {
        setUrlVideo(item)
        openModal()
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                {/* <Menu /> */}
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h3><b>{academia}</b></h3>
                                <button onClick={() => window.location = '/homeAtleta'} style={{ marginTop: 20, width: '30%' }} className="btn btn-primary d-grid " type="submit">{'< Voltar'}</button>
                                <div className="col-sm-12 col-lg-12 mb-4" style={{ marginTop: 20 }}>
                                    <div className="card card-border-shadow-primary h-100">
                                        {transmissao && transmissao.id ?
                                            <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                <img style={{ width: 200, height: 200 }} src={transmissao.urlImagem} />
                                                <h2 style={{ marginTop: 20 }}>{transmissao.nome}</h2>
                                                <h5>TERMOS DE ACEITE</h5>
                                                <p>Versão : v3</p>
                                                <div>{parse(termo)}</div>
                                                <p>Valor para assistir esta transmissão</p>
                                                <h5 ><b>{transmissao.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></h5>

                                                <button onClick={() => realizaTransmissao()} style={{ marginTop: 20 }} className="btn btn-success d-grid w-100" type="submit">
                                                    <i className="menu-icon tf-icons bx bx-video" />Concordar e Assistir Transmissão
                                                </button>
                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                                {isModalOpen && urlVideo && <Modal onClose={closeModal} playerNumber={urlVideo} transmissao={transmissao} />}
                                {isModalOpenPix && <ModalPix textToCopy={copiaCola} qrcode={qrcode} onClose={closeModalPix} />}
                            </div>
                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}