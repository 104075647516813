import React, { useEffect } from 'react';
import './app.css';
import { auditoria } from '../../../services/mysql';

const Modal = ({ onClose, url }) => {

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userd7'))
    const data = {
      idUsuario: user.id,
      acao: 'Abriu o modal para assistir o vídeo '
    }
    auditoria(data);
  }, [])

  return (
    <div className="modal">
      <div className="modal-content" >
        <span className="close" onClick={onClose}>&times;</span>
        <iframe width="100%" height="100%" src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <button onClick={onClose} className="btn btn-default d-grid w-100" type="submit">Fechar</button>
      </div>
    </div>
  );
}

export default Modal;
