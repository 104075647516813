import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { criaAssinatura } from '../../services/mysql'

export default function Login() {
    const queryParams = new URLSearchParams(window.location.search);
    const [er] = useState(queryParams.get('er'))
    const [nome] = useState(queryParams.get('nome'))
    const [iu] = useState(queryParams.get('iu'))
    const [e] = useState(queryParams.get('e'))
    const [deviceId, setDeviceId] = useState("");
    const [lista, setLista] = useState([])

    useEffect(() => {
        mensagemLoading('Redirecionando para pagamento..')
        const data = {
            external_reference: er,
            nome: nome,
            id_usuario:iu,
            email:e,
        }
        criaAssinatura(data).then((d) => {
            if (d.erro) {
                mensagemErro('Erro ao realizar assinatura! Entre em contato com o adminastrador')
            } else {
                window.location.href = d.data.init_point
            }
        })

    }, [])

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                    {/* Register */}
                    <div className="card">
                        <div className="card-body">
                            <input type="hidden" id="deviceId" />

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}