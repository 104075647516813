import React, { useRef, useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import Modal from './Modal';
import { auditoria } from '../../../services/mysql';

import { buscaVideos, verificaVideo, criaVideoUser, criaPix, buscaPagamento, listaVideoUser } from '../../../services/mysql'
import ModalPix from './ModalPix';

export default function Videos() {
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('i'))
    const [academia] = useState(queryParams.get('a'))
    const [videos, setVideos] = useState([])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [isModalOpenPix, setIsModalOpenPix] = useState(false);
    const openModalPix = () => setIsModalOpenPix(true);
    const closeModalPix = () => setIsModalOpenPix(false);

    const [urlVideo, setUrlVideo] = useState('')

    // Pagamento
    const [qrcode, setQrcode] = useState('')
    const [copiaCola, setCopiaCola] = useState('')
    const intervalId = useRef(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userd7'))
        mensagemLoading('Buscando Videos...')
        
        listaVideoUser(user.id, id).then((res) => {
            if (res.erro) {
                Swal.close()
                mensagemErro('Erro ao buscar vídeos')
            } else {
                if (res.length === 0) {
                    Swal.close()
                    mensagemAlerta('Sem vídeos para este evento ainda!')
                } else {
                    Swal.close()
                    setVideos(res)
                    const data = {
                        idUsuario: user.id,
                        acao: 'Acessou a página de Vídeos '
                    }
                    auditoria(data);
                }
            }
        })
    }, [])

    const startApiCalls = (pagamento, video) => {
        fetchApiData(pagamento, video);
        intervalId.current = setInterval(() => {
            fetchApiData(pagamento, video);
        }, 10000);
    };

    const fetchApiData = async (pagamento, video) => {
        try {
            buscaPagamento(pagamento).then((res) => {
                if (res.status === 'approved') {
                    const user = JSON.parse(localStorage.getItem('userd7'))
                    const data = {
                        idVideo: video.id,
                        idEvento: id,
                        idUser: user.id
                    }
                    criaVideoUser(data).then((resVideo) => {
                        if (resVideo.erro) {
                            mensagemErro('Erro ao cadastrar video')
                            const data = {
                                idUsuario: user.id,
                                acao: 'O sistema recebeu o erro no pagamento do vídeo '+video.nome
                            }
                            auditoria(data);
                        } else {
                            closeModalPix()
                            mensagemSucesso('Seu download já foi iniciado!')
                            handleDownload(video.urlDownload)
                            const data = {
                                idUsuario: user.id,
                                acao: 'O sistema recebeu o pagamento e iniciou o download do vídeo '+video.nome
                            }
                            auditoria(data);
                        }
                    })
                    clearInterval(intervalId.current);
                }
            });
        } catch (err) {
            mensagemErro('Erro ao realizar pagamento');
        }
    };

    function buscaVideo(videoBody) {
        const user = JSON.parse(localStorage.getItem('userd7'))
        mensagemLoading('Verificando..')
        verificaVideo(user.id, videoBody.id, id).then((res) => {
            if (res.erro) {
                mensagemErro('Erro ao verificar video')
                const data = {
                    idUsuario: user.id,
                    acao: 'Clicou em abrir o vídeo '+videoBody.nome
                }
                auditoria(data);
            } else {
                if (res.video || res.salaVip) {
                    Swal.close()
                    Swal.fire({
                        title: 'Aviso',
                        text: 'Você deseja realizar o download do vídeo? ',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Realizar Download',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            const data = {
                                idUsuario: user.id,
                                acao: 'Clicou em realizar download do vídeo '+videoBody.nome
                            }
                            auditoria(data);
                            handleDownload(videoBody.urlDownload)
                            mensagemLoading('Já estamos realizando seu Download! Caso esteja usando seu smartphone, ele aparecerá na area de notificação. Assim que realizar o downlaod, voce pode fechar esta tela')
                        }
                    });
                } else {
                    Swal.fire({
                        title: 'Aviso',
                        text: 'Você precisa realizar o pagamento de ' + videoBody.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) + ' para assistir o vídeo ' + videoBody.nome + '! Deseja realizar o pagamento?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Pagar',
                        cancelButtonText: 'Agora Não'
                    }).then((result) => {
                        if (result.value) {
                            const dados = {
                                nome: 'D7Producoes - Video',
                                cpf: user.cpf,
                                email: user.email,
                                descricao: 'Vídeo - ' + videoBody.nome,
                                valor: parseFloat(videoBody.valor),
                            }
                            mensagemLoading('Gerando pix..')
                            criaPix(dados).then((res) => {
                                setQrcode(res.data.pagamento.qrcode)
                                setCopiaCola(res.data.pagamento.chave)
                                Swal.close()
                                startApiCalls(res.data.id, videoBody)
                                openModalPix()
                                const data = {
                                    idUsuario: user.id,
                                    acao: 'Clicou em gerar o pix do vídeo '+videoBody.nome
                                }
                                auditoria(data);
                            })
                        }
                    });
                }
            }
        })
    }

    const extractFileIdGoogle = (url) => {
        const regex = /\/d\/(.*?)\//;  // Expressão regular para pegar o ID entre "/d/" e "/"
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const handleDownload = (urlVideo) => {
        console.log(urlVideo)
        if (!urlVideo) {
            alert('A URL do vídeo é necessária');
            return;
        }

        // Cria dinamicamente um link para fazer a requisição ao backend com a URL do vídeo
        const link = document.createElement('a');
        link.href = `https://api.tvcombate.com/downloadVideo?url=${encodeURIComponent(urlVideo)}`;  // Envia a URL como query parameter

        // Força a abertura da janela de download
        link.target = '_blank';  // Abre o link em uma nova aba (alguns navegadores tratam isso como uma nova ação de download)
        link.download = '';      // O atributo download vazio forçará o diálogo de download para o usuário escolher onde salvar

        // Simula o clique no link para iniciar o download
        document.body.appendChild(link);
        link.click();

        // Remove o link do DOM após o clique
        document.body.removeChild(link);
    };

    function assitirVideo(item) {
        setUrlVideo(item)
        openModal()
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                {/* <Menu /> */}
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <h3><b>Vídeos do Evento - {academia}</b></h3>
                                <button onClick={() => window.location = '/homeAtleta'} style={{ marginTop: 20, width: '30%' }} className="btn btn-primary d-grid " type="submit">{'< Voltar'}</button>
                                {videos && videos.length > 0 ?
                                    <div className="row" style={{ marginTop: 20 }}>
                                        {videos.map((academia) =>
                                            <div className="col-sm-6 col-lg-6 mb-4">
                                                <div className="card card-border-shadow-primary h-100">
                                                    <div className="card-body" style={{ alignItems: 'center', textAlign: 'center' }}>
                                                        <img style={{ width: 100, height: 100 }} src={academia.imagem} />
                                                        <h5 style={{ marginTop: 20 }}>{academia.nome}</h5>
                                                        <p className="mb-1">{academia.descricao}</p>
                                                        <p>{academia.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                                                        <button onClick={() => assitirVideo(academia.url)} style={{ marginTop: 20 }} className="btn btn-info d-grid w-100" type="submit">
                                                            <i className="menu-icon tf-icons bx bx-video" /> Assistir Prévia
                                                        </button>
                                                        {academia.atleta ?
                                                            <button onClick={() => buscaVideo(academia)} style={{ marginTop: 20 }} className="btn btn-success d-grid w-100" type="submit">
                                                                <i className="menu-icon tf-icons bx bx-lock-open" /> Download do vídeo
                                                            </button>
                                                            :
                                                            <button onClick={() => buscaVideo(academia)} style={{ marginTop: 20 }} className="btn btn-danger d-grid w-100" type="submit">
                                                                <i className="menu-icon tf-icons bx bx-lock" /> Download do vídeo
                                                            </button>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    :
                                    null
                                }
                                {isModalOpen && urlVideo && <Modal onClose={closeModal} url={urlVideo} />}
                                {isModalOpenPix && <ModalPix textToCopy={copiaCola} qrcode={qrcode} onClose={closeModalPix} />}
                            </div>
                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}