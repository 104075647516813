import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2'
import _ from 'lodash';
import { listaEventos, buscaPagamentos, listaTransmissoes } from '../../services/mysql'
import moment from 'moment/moment';

export default function Pagamentos() {
    const [academias, setAcademias] = useState([])
    const [eventos, setEventos] = useState([])
    const [transmissoes, setTransmissoes] = useState([])
    const [evento, setEvento] = useState('')
    const [mesAno, setMesAno] = useState(moment().format('YYYY-MM'))
    const [tipoEvento, setTipoEvento] = useState('eventos')
    const [filtro] = useState({
        anoMes: moment().format('YYYY-MM'),
        idEvento: null,
        tipoEvento: 'eventos'
    })
    useEffect(() => {
        mensagemLoading('Buscando Pagamentos...')
        listaEventos().then((res) => {
            if (res.erro) {
                mensagemErro(res.message)
            } else {
                setEventos(res)
            }
        })
        listaTransmissoes().then((res) => {
            if (res.erro) {
                mensagemErro(res.message)
            } else {
                setTransmissoes(res)
            }
        })
        console.log(filtro)
        buscaPagamentos(filtro).then((res) => {
            if (res.erro) {
                Swal.close()
                mensagemErro(res.message)
            } else {
                Swal.close()
                console.log(res.pagamentos)
                setAcademias(res.pagamentos)
            }
        })
    }, {})

    function filtrar() {
        setAcademias([])
        filtro.anoMes = mesAno
        filtro.idEvento = evento ? evento : null
        filtro.tipoEvento = tipoEvento
        mensagemLoading('Buscando pagamentos...')
        buscaPagamentos(filtro).then((res) => {
            if (res.erro) {
                Swal.close()
                mensagemErro(res.message)
            } else {
                Swal.close()
                setAcademias(res.pagamentos)
            }
        })

    }

    const handleMesAno = (event) => {
        event.persist();
        setMesAno(event.target.value);
    }
    const handleTipoPagamento = (event) => {
        setAcademias([])
        event.persist();
        if (event.target.value === 'transmissao') {
            setTipoEvento('transmissao')
        } else {
            setTipoEvento('eventos')
        }
    }
    const handleEvento = (event) => {
        event.persist();
        if (event.target.value === 'all') {
            setEvento(null)
        } else {
            setEvento(event.target.value);
        }
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            mensagemLoading('Atualizando Eventos...')
            listaEventos().then((res) => {
                Swal.close()
                setAcademias(res)
            })
        })
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 " style={{ marginTop: 20 }}>
                                <div className="card">
                                    <div className='row'>
                                        <div className='col-md-10'>
                                            <h5 className="card-header">Pagamentos</h5>
                                        </div>
                                    </div>
                                    <div className='card-body row'>
                                        <div class="col-md-2">
                                            <label class="form-label" for="basic-icon-default-fullname">Tipo de Pagamento</label>
                                            <select id="defaultSelect" className="form-select" onChange={handleTipoPagamento}  >
                                                <option>Selecione</option>
                                                <option value={'eventos'} selected={tipoEvento === 'eventos'?'selected':null}>Eventos</option>
                                                <option value={'transmissao'} selected={tipoEvento === 'transmissao'?'selected':null}>Transmissões</option>
                                            </select>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-label" for="basic-icon-default-fullname">Mes/Ano</label>
                                            <input value={mesAno} onChange={handleMesAno} type="month" class="form-control" />
                                        </div>
                                        {tipoEvento == 'eventos' ?
                                            <div class="col-md-4">
                                                <label class="form-label" for="basic-icon-default-fullname">Por Evento</label>
                                                <select id="defaultSelect" className="form-select" onChange={handleEvento}  >
                                                    <option>Selecione</option>
                                                    <option value={'all'}>Todos</option>
                                                    {eventos.map((evento) =>
                                                        <option value={evento.id} >{evento.nome}</option>
                                                    )}
                                                </select>
                                            </div>
                                            :
                                            <div class="col-md-4">
                                                <label class="form-label" for="basic-icon-default-fullname">Por Transmissão</label>
                                                <select id="defaultSelect" className="form-select" onChange={handleEvento}  >
                                                    <option>Selecione</option>
                                                    <option value={'all'}>Todos</option>
                                                    {transmissoes.map((evento) =>
                                                        <option value={evento.id} >{evento.nome}</option>
                                                    )}
                                                </select>
                                            </div>
                                        }

                                        <div class="col-md-4" style={{ marginTop: 30 }}>
                                            <button onClick={() => filtrar()} type="submit" className="btn btn-primary me-2">Filtrar</button>
                                        </div>
                                    </div>
                                    <div className="table-responsive text-nowrap">
                                        {tipoEvento === 'eventos' ?
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Atleta</th>
                                                        <th>CPF</th>
                                                        <th>Email</th>
                                                        <th>Evento</th>
                                                        <th>Vídeo</th>
                                                        <th>Valor</th>
                                                        <th>Data Pagamento</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-border-bottom-0">
                                                    {academias.sort((a, b) => moment(b.dataPagamento).diff(moment(a.dataPagamento))).map((pagamento, index) =>
                                                        <tr key={index}>
                                                            <td>{pagamento.nomeUsuario}</td>
                                                            <td>{pagamento.cpf}</td>
                                                            <td>{pagamento.email}</td>
                                                            <td>{pagamento.nomeEvento}</td>
                                                            <td>{pagamento.nomeVideo}</td>
                                                            <td>{pagamento.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                            <td>{moment(pagamento.dataPagamento).format('DD/MM/YYYY - HH:mm')}</td>
                                                        </tr>
                                                    )}
                                                    {academias.length === 0 ?
                                                        <p style={{ textAlign: 'center', padding: 10 }} >Sem eventos pagamentos</p>
                                                        :
                                                        null
                                                    }

                                                </tbody>
                                            </table>
                                            :
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Atleta</th>
                                                        <th>CPF</th>
                                                        <th>Email</th>
                                                        <th>Transmissao</th>
                                                        <th>Valor</th>
                                                        <th>Data Pagamento</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-border-bottom-0">
                                                    {academias.sort((a, b) => moment(b.dataPagamento).diff(moment(a.dataPagamento))).map((pagamento, index) =>
                                                        <tr key={index}>
                                                            <td>{pagamento.nomeUsuario}</td>
                                                            <td>{pagamento.cpf}</td>
                                                            <td>{pagamento.email}</td>
                                                            <td>{pagamento.nome}</td>
                                                            <td>{pagamento.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                            <td>{moment(pagamento.dataPagamento).format('DD/MM/YYYY - HH:mm')}</td>
                                                        </tr>
                                                    )}
                                                    {academias.length === 0 ?
                                                        <p style={{ textAlign: 'center', padding: 10 }} >Sem pagamentos</p>
                                                        :
                                                        null
                                                    }

                                                </tbody>
                                            </table>
                                        }

                                    </div>
                                </div>

                            </div>

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}